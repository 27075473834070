<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'passportNumber')"
              :label="$t('hr.employee.personal.pasaport_number')"
              rules="max:50"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.passportNumber"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'birthDate')"
              :label="$t('hr.employee.personal.birth_date')"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <date-picker
                  v-model="employeePayload.birthDate"
                  outlined
                  dense
                  color="tertiary"
                  styles="max-width: 200px;"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'placeOfBirth')"
              :label="$t('hr.employee.personal.place_of_birth')"
              rules="max:512"
              :index="2"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.placeOfBirth"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 300px;"
                  counter="512"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'nationality')
                  ? $t(`nationalities.${$helpers.get(employee, 'nationality')}`)
                  : null
              "
              :label="$t('hr.employee.personal.nationality')"
              :index="3"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <nationality-picker
                  v-model="employeePayload.nationality"
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'gender') ? $t(`gender.${$helpers.get(employee, 'gender')}`) : null"
              :label="$t('hr.employee.personal.gender')"
              rules="required"
              :index="4"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <gender-picker
                  v-model="employeePayload.gender"
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'bloodType')
                  ? $t(`blood_type.${$helpers.get(employee, 'bloodType').toLowerCase()}`)
                  : null
              "
              :label="$t('hr.employee.personal.blood_type')"
              :index="5"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <blood-type-picker
                  v-model="employeePayload.bloodType"
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'militaryService')
                  ? $t(`military_service.${$helpers.get(employee, 'militaryService').toLowerCase()}`)
                  : null
              "
              :label="$t('hr.employee.personal.military_service')"
              :index="6"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <military-service-picker
                  v-model="employeePayload.militaryService"
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'hesCode')"
              :label="$t('hr.employee.personal.hes_code')"
              :index="7"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.hesCode"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'expireDate')"
              :label="$t('hr.employee.personal.hes_expired_date')"
              :index="8"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <date-picker
                  v-model="employeePayload.expireDate"
                  outlined
                  dense
                  color="tertiary"
                  styles="max-width: 200px;"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'levelOfDisability')
                  ? $t(`disability_level.${$helpers.get(employee, 'levelOfDisability').toLowerCase()}`)
                  : null
              "
              :label="$t('hr.employee.personal.disability_level')"
              :index="9"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <disability-level-picker
                  v-model="employeePayload.levelOfDisability"
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'exConvict') ? $t('buttons.yes') : $t('buttons.no')"
              :label="$t('hr.employee.personal.ex_convict')"
              :index="10"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-switch v-model="employeePayload.exConvict" class="mt-1" />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'driver') ? $t('buttons.yes') : $t('buttons.no')"
              :label="$t('hr.employee.personal.driver')"
              :index="11"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-switch v-model="employeePayload.driver" class="mt-1" />
              </template>
            </profile-field>
          </v-col>
          <template v-for="(objectAttributeValue, idx) in employeePayload.objectAttributeValues">
            <v-col :key="idx" cols="12" class="py-0">
              <profile-field
                :value="objectAttributeValue.value"
                :label="objectAttributeValue.name"
                :index="12 + idx"
                :active-index="activeIndex"
                :saving="saving"
                :loading="loading"
                :is-editable="isEditable"
                @click:edit="onEditClick"
                @click:save="handleSubmit(onSaveClick)"
                @click:cancel="onCancelClick"
              >
                <template v-slot="{ errors }">
                  <v-text-field
                    v-model="objectAttributeValue.value"
                    outlined
                    dense
                    color="tertiary"
                    style="max-width: 400px;"
                    :error-messages="errors"
                  />
                </template>
              </profile-field>
            </v-col>
          </template>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_PERSONAL } from "../query";

  export default {
    name: "EmployeePersonal",
    props: {
      profileSettings: Object
    },
    components: {
      BloodTypePicker: () => import("@/components/selectpickers/BloodTypePicker"),
      MilitaryServicePicker: () => import("@/components/selectpickers/MilitaryServicePicker"),
      DisabilityLevelPicker: () => import("@/components/selectpickers/DisabilityLevelPicker"),
      NationalityPicker: () => import("@/components/selectpickers/NationalityPicker"),
      GenderPicker: () => import("@/components/selectpickers/GenderPicker"),
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        passportNumber: null,
        birthDate: null,
        placeOfBirth: null,
        nationality: null,
        gender: null,
        bloodType: null,
        militaryService: null,
        hesCode: null,
        expireDate: null,
        levelOfDisability: null,
        exConvict: null,
        driver: null,
        objectAttributeValues: []
      },
      employee: null,
      activeIndex: -1,
      saving: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.PERSONAL);
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updatePersonalCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.fetchEmployeePersonal();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload = { ...employee };
        this.employeePayload.objectAttributeValues = this.$helpers.cloneDeep(employee.objectAttributeValues);
      },
      async fetchEmployeePersonal() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_PERSONAL,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee.personal;
              this.setEmployeePayload(data.employee.personal);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeePersonal();
    }
  };
</script>
